import { FeatureData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../core/sysext/Boutique/containers/Produit/containers/Livraison/components/List/Item';
import params from '../../core/sysext/Boutique/containers/Produit/containers/Livraison/params.json';
import CMSView from '../../core/sysext/CMS/components/View';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import Form from '../../core/sysext/CMS/containers/Boutique/components/new/Form';
import List from '../../core/sysext/CMS/containers/Boutique/components/new/List';

const LivraisonsPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;
  const { t } = useTranslation();

  const boutique = useSelector((state: any) =>
    state.cms.boutiques
      ? state.cms.boutiques[state.cms.indexBoutique || 0]
      : undefined,
  );

  const model = new FeatureData(boutique, {
    params,
    collectionName: 'livraisons',
  });

  return (
    <Backoffice location={location}>
      <CMSView>
        {!star ? (
          <List
            {...props}
            formName="livraison"
            item={Item}
            itemPathnamePrefix="/boutique/livraisons/"
            model={model}
            nbParPage={50}
            title={t('livraisons')}
          />
        ) : (
          <Form
            {...props}
            docId={star}
            itemPathnamePrefix="/boutique/livraisons/"
            model={model}
            name="livraison"
          />
        )}
      </CMSView>
    </Backoffice>
  );
};

export default LivraisonsPage;
